import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const Privacy = ({ data }) => {

	const privacies = data.allMarkdownRemark.nodes;

	return (
		<Layout>
            <SEO title="Privacy Policy" description="Appvity Privacy policy is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the appvity.com website." meta={[{ name: `keywords`, content: ["Appvity privacy policies"] }]} pathname="/privacy-policy" />
			<div className="main-content-padding">
				<div className="app-text-template-introduction privacy-policy" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <h2>Privacy Policy</h2>
                    </Container>
                </div>
				<div className="app-general-seciton app-text-template-body" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
							<Col xs={12} md={12} lg={1}></Col>
							<Col xs={12} md={12} lg={3}>
								<ul className="app-text-template-body-detail-left">
									{privacies.map((res) => (
										<li 
											key={res.id} 
											onClick={() => {
												var scrollDiv = document.getElementById(res.frontmatter.keyNumber).offsetTop + 340;
												window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
											}}
										>{res.frontmatter.title}</li>
									))}
								</ul>
							</Col>
							<Col xs={12} md={12} lg={7}>
								<ul className="app-text-template-body-detail-right">
									{privacies.map((res) => (
										<li key={res.id} id={res.frontmatter.keyNumber}><h5>{res.frontmatter.title}</h5>
											<div dangerouslySetInnerHTML={{ __html: res.html }} />
										</li>
									))}
								</ul>
							</Col>
							<Col xs={12} md={12} lg={1}></Col>
						</Row>
                    </Container>
                </div>
			</div>
		</Layout>
	)
}

export default Privacy;

export const query = graphql`
    query PrivacyPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "privacies" } } }
			sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
					keyNumber
                }
                id
            }
        }
    }
`;
